import React, { Component } from 'react'
import { Col, Row, Card, CardBody } from 'reactstrap'

import PopupIcon1 from '../../images/popup-types/icon-slide-in.png'
import PopupIcon2 from '../../images/popup-types/icon-fullscreen-overlays.png'
import PopupIcon3 from '../../images/popup-types/icon-spin-the-wheel.png'
import PopupIcon4 from '../../images/popup-types/icon-countdown-timers.png'
import PopupIcon5 from '../../images/popup-types/icon-floating-bars.png'
import PopupIcon6 from '../../images/popup-types/icon-exit-popups.png'

class HomePopupTypes extends Component {
    render() {
        return (
            <div className="home-popup-types-section">
                <div className="container">
                    <div className="hero-content wow fadeIn text-center mb-5">
                        <h2>Powerful Features to Build Highly Converting <span className="style-highlight"> eCommerce Popups 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span></h2>
                        <p className="pb-5">Collect your captured email leads in one place. Seamlessly integrate with some of the best ESPs and webhooks in the industry. And carry out multiple actions in autopilot.</p>
                        <h3>Different Types of eCommerce Popups</h3>
                    </div>
                    <Row>
                        <Col md="4">
                            <Card className="text-center mx-2 mb-5">
                                <CardBody>
                                    <img src={PopupIcon1} alt="" />
                                    <h4>Slide-in Popup</h4>
                                    <p>Optinly lets you display Slide-in popups on the left/right of your screen and floating bars at the top/bottom of your screen.</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="text-center mx-2 mb-5">
                                <CardBody>
                                    <img src={PopupIcon2} alt="" />
                                    <h4>Fullscreen Overlays</h4>
                                    <p>Make store visitors focus only on what you’re about to say. Use full screen overlays to display offers/deals to customers on your store.</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="text-center mx-2 mb-5">
                                <CardBody>
                                    <img src={PopupIcon3} alt="" />
                                    <h4>Spin the Wheel Popups</h4>
                                    <p>Capture leads and drive sales simultaneously using spin to win popups. Increase engagement the super cool way.</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="4">
                            <Card className="text-center mx-2 mb-5">
                                <CardBody>
                                    <img src={PopupIcon4} alt="" />
                                    <h4>Countdown Timers</h4>
                                    <p>Induce FOMO (Fear of Missing Out) into your audience.Use countdown timer popups to create FOMO and witness steep sales numbers.</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="text-center mx-2 mb-5">
                                <CardBody>
                                    <img src={PopupIcon5} alt="" />
                                    <h4>Floating Bars</h4>
                                    <p>Ask visitors to signup in the most gentle way possible. Use from a collection of Optinly’s floating bar templates to capture leads.</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="text-center mx-2 mb-5">
                                <CardBody>
                                    <img src={PopupIcon6} alt="" />
                                    <h4>Coupon Popups</h4>
                                    <p>Coupon popup templates to increase sales on your eCommerce store. Customers can copy coupons to clipboard and increase purchase value seamlessly.</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default HomePopupTypes
