import React from 'react'
import Layout from '../../components/layout'
import Metatags from "../../components/Metatags"
//import home page components
import GetStarted from '../../components/getstarted'
import Banner from '../../components/Banner';
// import Integrations from '../constants/HomePage/Integrations'
import HomeBanner from '../../constants/Home/Banner';
import HomeIntegrationCompanies from '../../constants/Home/IntegrationCompanies';
import HomeCounter from '../../constants/Home/homeCounter';
import HomePopupFeatures from '../../constants/IncreaseEcommerceSales/HomePopupFeatures';
import HomePopupTypes from '../../constants/IncreaseEcommerceSales/HomePopupTypes';
import HomePopupDisplayRules from '../../constants/IncreaseEcommerceSales/HomePopupDisplayRules';
import PopupEmailEditor from '../../constants/IncreaseEcommerceSales/PopupEmailEditor';
import HomePopupClients from '../../constants/Home/HomePopupClients';
import CookieConsent from "../../components/cookieconsent";
import SwiperSlider from '../../components/SwiperSlider';
import Bg from '../../images/background-1.png';
import SliderImg1 from '../../images/mike.png';
import SliderImg2 from '../../images/jockbrocas.png';
import SliderImg3 from '../../images/caseyjones.png';


const Title = () => (
    <><h1><span className="style-highlight">Increase eCommerce Sales.
        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg>
    </span> Use eCommerce Popups to Grow Sales & Revenue.</h1></>
);

const Slides = [
    {
        content: `
            <div className="swiper-slide-contents">
                <div className="elementor-slide-heading">"Was Not a fan of Popups, Until Now!"</div>
                <div className="elementor-slide-description">What I like here is, there is no subscriber limit and their templates are amazing. It also has a dashboard to monitor conversions/campaigns for all my website/clients!<br/><br/>
                <b>Mike</b> - mrdzyn.com 
                </div>
            </div>`,
        img: SliderImg1
    },
    {
        content: `
            <div className="swiper-slide-contents"><div className="elementor-slide-heading">"I Ditched Optinmonster&#8203;"</div><div className="elementor-slide-description">This has been the answer to my needs. I have jumped all in and now that I have played with it, I am slowly moving all my sites over to Optinly.<br><br><b>JockBrocas</b> - jockbrocas.com</div>
            </div>`,
        img: SliderImg2
    },
    {
        content: `
            <div className="swiper-slide-contents"><div className="elementor-slide-heading">Love Optinly!</div><div className="elementor-slide-description">One word pretty well sums it up - Awesome!<br><br>
            <b>Casey Jones</b> - www.cjco.com.au</div></div>`,
        img: SliderImg3
    },
]

const SwiperSlides = () => (
    <div className="position-relative">
        <SwiperSlider slidesPerView={1} slides={Slides} spaceBetween={0} slideDeley={5000} />
    </div>
);

const IndexPage = () => (
    <Layout className="home-page">
        <Metatags
            title="Increase eCommerce Sales with Optinlyl"
            description = "Increase your ecommerce sales with Optinly. Create lead capturing forms and exit intent popups to drive conversions, reduce cart abandonment, and grow your email list."
            keywords= {[`exit popup`, `popup maker`, `free exit popup generator`, `email optins Features Page`]}
            url = "https://www.optinly.com"
            pathname = "/"
        />
        <Banner title={<Title />} description="Use smart website popups to grow store revenue seamlessly. Reduce cart abandonment, promote special offers and do more with Optinly!" actionUrl="https://app.optinly.com/?utm_source=Landing&utm_medium=cta&utm_campaign=emailpopups" actionText={"Signup for Optinly"} slider={<SwiperSlides />} bgImg={Bg} />
        <HomeIntegrationCompanies />
        <HomeCounter />
        <HomePopupFeatures />
        <HomePopupTypes />
        <HomePopupDisplayRules />
        <PopupEmailEditor />
        <HomePopupClients />
        <GetStarted title="Start Growing Your eCommerce Revenue With Optinly Today" />
        {/* <SignupForm/> */}
        {/* <HomeFeatures/> */}
        {/* <Integrations/> */}
        {/*<HomeUseCase/>*/}
        {/* <div className="row d-flex justify-content-center">
            <CookieConsent 
            className="container" style={{borderRadius:'5px' }}  
            buttonStyle={{color:"Black", fontWeight: "bolder",borderRadius:'5px'}}>
                <p>We use cookies to improve your experience on our website.</p>
            </CookieConsent>
        </div> */}
    </Layout>
);

export default IndexPage;
