import React from 'react'
import { Link } from 'gatsby';
import { Row, Col } from 'reactstrap';
import { FaArrowRight } from 'react-icons/fa';


const Banner = (props) => {
    return (
        <div className="home-hero-container">
            <div className="container">
                <div className="text-container">
                    <Row>
                        <Col md="6">
                            <div className="hero-content wow fadeIn">
                                {props.title &&
                                   <>
                                        {props.title}
                                   </>
                                    
                                }
                                {props.description &&
                                    <p>
                                        {props.description}
                                    </p>
                                }
                                {props.actionUrl &&
                                    <div>
                                        <a className="btn btn-secondary" href={props.actionUrl} target="_blank">
                                            {props.actionText} <FaArrowRight />
                                        </a>
                                    </div>
                                }
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="position-relative">
                                {props.slider &&
                                    props.slider
                                }
                                {props.bgImg &&
                                    <img src={props.bgImg} className="banner-bg" alt=""/>
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Banner
