import React, { Component } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { FaArrowRight } from 'react-icons/fa';

import FeatureIcon1 from '../../images/features/multiple-popup-triggering/exit-intent-popup.png'
import FeatureIcon2 from '../../images/features/multiple-popup-triggering/device-based.png'
import FeatureIcon3 from '../../images/features/multiple-popup-triggering/page-based.png'
import FeatureIcon4 from '../../images/features/multiple-popup-triggering/delay.png'
import FeatureIcon5 from '../../images/features/increase-ecommerce-sales/same-visitor.png'

export class HomePopupFeatures extends Component {
    render() {
        return (
            <>
                <div className="home-popup-features-section pb-0 bg-white">
                    <div className="container">
                        <Row className="align-items-center text-center pt-3">
                            <Col md="12">
                                <div className="hero-content wow fadeIn">
                                    <h2>Powerful eCommerce <span className="style-highlight"> Popup Display Rules
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span></h2>
                                    <p>Trigger eCommerce popups when it really matters. Use popup triggers at places like cart pages, product pages and more. Help visitors make their first purchase and in turn, help yourself achieve multiple goals.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="home-multi-popup-trigger-features-section">
                    <div className="container">
                        <Row className="align-items-center py-5">
                            <Col md="6">
                                <div className="mb-4 ps-3 pe-5">
                                    <h3>Exit Intent Triggers</h3>
                                    <p>Use last-minute windows wisely using exit intent triggers. Stop visitors from abandoning shopping carts by displaying exciting deals/discounts.</p>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon1} alt="" />
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center py-5">
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon3} alt="" />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4 ps-5 pe-3">
                                    <h3>URL Based Triggers</h3>
                                    <p>Run separate popup campaigns for men and women. Use page-based triggers to include/exclude URLs and make campaigns more targeted with ease.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div class="elementor-shape elementor-shape-bottom" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path class="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                    <path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                    <path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg> </div>
                </div>
                <div className="home-multi-popup-trigger-features-section">
                    <div className="container">
                        <Row className="align-items-center py-5">
                            <Col md="6">
                                <div className="mb-4 ps-3 pe-5">
                                    <h3>Device-Based Triggers</h3>
                                    <p>Run campaigns on multiple devices simultaneously or on selected devices. Choose between mobiles, desktops and tablets to run campaigns.</p>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon2} alt="" />
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center pt-5">
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon4} alt="" />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4 ps-5 pe-3">
                                    <h3>Time-Delayed Triggers</h3>
                                    <p>Welcome first-time visitors with a time-delayed popup. Greet them with a coupon/discount offer a couple of seconds after they land on your webpage. And turn new visitors into store customers.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div class="elementor-shape elementor-shape-bottom" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path class="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                    <path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                    <path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg> </div>
                </div>
                <div className="home-popup-features-section bg-white">
                    <div className="container">
                        <Row className="align-items-center pt-5">
                            <Col md="6">
                                <div className="mb-4 ps-3 pe-5">
                                    <h3>Retarget Visitors</h3>
                                    <p>Didn’t purchase the first time? Try a week after maybe! Optinly comes with retargeting options that allow you to display popup campaigns to individuals after a specific timeline.</p>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon5} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }
}

export default HomePopupFeatures
