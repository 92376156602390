import React, { Component } from 'react'
import { Col, Row } from 'reactstrap';

import EditorImg from '../../images/features/email-popups/user-friendly-popup-editor-1.png';

class HomePopupTestimonials extends Component {
    render() {
        return (
            <div className="popup-email-editor-section">
                <div className="elementor-shape elementor-shape-top" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path className="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                        <path className="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                        <path className="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg>
                </div>
                <div className="container">
                    <div className="hero-content wow fadeIn text-center mb-5">
                        <Row className="justify-content-center pt-5">
                            <Col md="11">
                                <h2>User-Friendly eCommerce <span className="style-highlight"> Popup Editor
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span></h2>
                                <p>Tweak your eCommerce popup templates the way your visitors deserve them. Create visually appealing popups without having the need to hire a designer/developer. Customize popups elements extensively and go live in minutes.</p>
                            </Col>
                        </Row>
                    </div>
                    <div className="position-relative">
                        <Row className="justify-content-center align-items-center">
                            <Col md="6">
                                <img src={EditorImg} />
                            </Col>
                            <Col md="5">
                                <ul>
                                    <li>Customize popup copy text, color, font styling and more.</li>
                                    <li>Create custom CTA buttons - change the background color, text style and add redirect URLs.</li>
                                    <li>Change popup template image - choose from 1 million + images or upload from local storage</li>
                                    <li>Add popup transition effects like fade-in, flip-in, backin up and more.</li>
                                    <li>Ensure popup responsiveness on different devices using the device preview option.</li>
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}

export default HomePopupTestimonials
