import React, { Component } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'

import FeatureIcon1 from '../../images/features/increase-ecommerce-sales/cart-reduce.png'
import FeatureIcon2 from '../../images/features/increase-ecommerce-sales/promote.png'
import FeatureIcon3 from '../../images/features/increase-ecommerce-sales/increase-sale.png'
import FeatureIcon4 from '../../images/features/increase-ecommerce-sales/color-wheel.png'

export class HomePopupFeatures extends Component {
    render() {
        return (
            <div className="home-popup-features-section position-relative">
                <div className="container">
                    <Row className="align-items-center">
                        <Col md="8">
                            <div className="hero-content wow fadeIn">
                                <h2>How Optinly Helps Grow <br /> <span className="style-highlight"> Your eCommerce Business
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span></h2>
                                <p>Scale up your business to the next level. Increase eCommerce sales and  reduce cart abandonment. With Optinly, no goal is hard to achieve. Whether it’s growing your email list or increasing your sales, Optinly does the job pretty well – every single time.</p>
                            </div>
                        </Col>
                        <Col md="4">
                            <Card className="text-center mb-4">
                                <CardBody>
                                    <img src={FeatureIcon1} alt="" />
                                    <h5>Reduce Cart Abandonment</h5>
                                    <p>Prevent visitors from abandoning shopping carts. Use exit-intent popups to reduce cart abandonment rate. Come up with discounts/offers and give visitors a reason to stay!</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="py-5">
                        <Col md="4">
                            <Card className="text-center mb-4">
                                <CardBody>
                                    <img src={FeatureIcon2} alt="" />
                                    <h5>Promote Special Offers</h5>
                                    <p>Let visitors know what’s happening in your store. Display seasonal deals or holiday special offers using popups. Drive more traffic to specific product pages by placing custom links on popups.</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="text-center mb-4">
                                <CardBody>
                                    <img src={FeatureIcon3} alt="" />
                                    <h5>Increase Sales Using Coupons</h5>
                                    <p>Increase sales with engaging eCommerce coupons. With Optinly, create coupons and display them on popups. Allows customers to copy coupons to clipboard and witness increased sales figures.</p>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card className="text-center mb-4">
                                <CardBody>
                                    <img src={FeatureIcon4} alt="" />
                                    <h5>Gamification for Lead Capturing</h5>
                                    <p>Capture leads the super cool way with Optinly. Increase engagement with gamification popups. Use pre-existing spin wheel popup templates to capture leads and deliver coupons.</p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
                <div class="elementor-shape elementor-shape-bottom" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                        <path class="elementor-shape-fill" fill="#fff" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                        <path class="elementor-shape-fill" fill="#fff" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                        <path class="elementor-shape-fill" fill="#fff" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg> </div>
            </div>
        )
    }
}

export default HomePopupFeatures
